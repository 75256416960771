import React from "react";
import StillsImages from "./images/page";
import StillsVideos from "./videos/page";

const Stills = () => {
  return (
    <div className="stills-page">
      <header className="stills-header text-center">
        <h1>Portfolio</h1>
        <p>
          Découvrez une sélection de mes meilleurs travaux en images et vidéos.
        </p>
      </header>
      <section className="stills-images">
        <h2 className="text-center mt-5">Images</h2>
        <StillsImages />
      </section>
      <section className="stills-videos">
        <h2 className="text-center m-5">Videos</h2>
        <StillsVideos />
      </section>
      {/* <style jsx>{`
        .stills-page {
          padding: 20px;
        }
        .stills-header {
          text-align: center;
          margin-bottom: 40px;
        }
        .stills-images,
        .stills-videos {
          margin-bottom: 40px;
        }
        h1,
        h2 {
          font-size: 2rem;
          margin-bottom: 20px;
        }
        p {
          font-size: 1.2rem;
        }
      `}</style> */}
    </div>
  );
};

export default Stills;
