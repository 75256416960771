
import React from "react";
import { Card, Container } from "react-bootstrap";
import "./stillCard.scss"; 

const StillCard = ({src,title}) => {
 
  return (
    <Container>
      <Card className="still-card">
        <div className="image-wrapper">
          <Card.Img
            variant="top"
            src={src}
            alt={title}
            className="img-fluid still-card-img"
          />
        </div>
      </Card>
    </Container>
  );
};

export default StillCard;
