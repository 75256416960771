import React, { useEffect, useState } from "react";

import { Col, Container, Image, Row } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";
import "./about.scss";

export const metadata = {
  title: " À propos",
  description:
    "Découvrez le parcours de Saleh Ismael dans le cinéma et l'audiovisuel.",
};

const About = () => {
  const [inView, setInView] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector(".about");
      if (element) {
        const { top, bottom } = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        // Trigger animations earlier by adjusting the threshold
        if (top < viewportHeight * 0.75 && bottom >= 0) {
          setInView(true);
        } else {
          setInView(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Call on mount to check initial position

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [inView, controls]);

  return (
    <div className="about">
      <Image
        src="/images/9.jpg"
        alt="background"
        className="img-fluid "
        layout="responsive"
        loading="lazy"
      />

      <Container className="d-flex flex-column align-items-center">
        {/* Title Animation */}
        <motion.h1
          className="my-3 text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
          transition={{ duration: 1.5 }}
        >
          À propos de Saleh Ismael
        </motion.h1>

        <Row className="align-items-center">
          {/* Profile Image Animation */}
          <Col lg={6} className="d-flex justify-content-center">
            <motion.div
              className="profil"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={controls}
              transition={{ duration: 1.5 }}
            >
              <Image
                src="/images/profil.jpg"
                width={200}
                height={200}
                alt="Saleh Ismael"
                className="img-fluid"
                loading="lazy"
              />
            </motion.div>
          </Col>
          <Col lg={6} className="p-5">
            {/* Text Animation */}
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={controls}
              transition={{ duration: 1.5, delay: 0.5 }}
            >
              Mon parcours dans le cinéma a débuté en 2015, après l’obtention de
              mon baccalauréat scientifique au Lycée Gabode à Djibouti, suivi
              d’une licence en Technique audiovisuelle à l’Université de
              Djibouti. En 2017, j’ai commencé à travailler chez{" "}
              <b>Pixel4Fiction</b> aux côtés de <b>Frédéric Dallo</b>, une
              expérience déterminante pour affiner mes compétences techniques.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={controls}
              transition={{ duration: 2, delay: 1 }}
            >
              En 2018, j’ai cofondé <b>SAYA FILM PRODUCTION</b>, ce qui m’a
              permis de réaliser plusieurs projets commerciaux pour des
              entreprises du marché local. En 2019, je suis parti en Turquie
              pour poursuivre un Master en Cinéma et Audiovisuel à l'Université
              de Çankiri Karatekin. En parallèle de mes études, j'ai suivi des
              formations privées en photographie de mode et direction
              artistique, approfondissant ainsi mes compétences dans
              l’audiovisuel.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={controls}
              transition={{ duration: 2.5, delay: 1.5 }}
            >
              Ensuite, j’ai rejoint <b>COLOR B STUDIO</b>, où j’ai participé à
              la post-production de plusieurs films et huit séries en tant
              qu’étalonneur. Cette expérience m’a permis de développer mes
              compétences dans l'industrie du cinéma turc ainsi que dans le
              domaine publicitaire.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={controls}
              transition={{ duration: 3, delay: 2 }}
            >
              Aujourd'hui, de retour à Djibouti, je travaille en tant que
              freelance, offrant une gamme complète de services. Je suis
              spécialisé dans la réalisation de vidéos publicitaires,
              documentaires, films promotionnels et institutionnels.{" "}
              <b>
                {" "}
                Je mets à votre disposition toutes les compétences acquises au
                cours de ces années pour mettre en lumière et valoriser votre
                entreprise de manière unique et percutante.
              </b>
            </motion.p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
