import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/common/header";
import Footer from "../components/common/footer";
import Spacer from "../components/common/spacer";
import ScrollToTop from "../components/scrollToTop";
import ScrollToTopButton from "../components/common/scroll-to-top-button";

const UserLayout = () => {
  const location = useLocation();

  // Check if the current path is the home page
  const isHomePage = location.pathname === "/";

  return (
    <div >
        <ScrollToTop />
      {/* Conditionally render Header, Spacer, and Footer on all pages except home */}
      {!isHomePage && <Header />}
      
      {/* Render the page content */}
      <Outlet />

      {/* Conditionally render Spacer and Footer on all pages except home */}
      {!isHomePage && <Spacer />}
      {!isHomePage && <Footer />}
      <ScrollToTopButton/>
    </div>
  );
};

export default UserLayout;
