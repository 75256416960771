import React from "react";
import { Carousel } from "react-bootstrap";
import "./slider.scss";

const HomePage = () => {
  // Tableau des vidéos
  const videos = [
    "/videos/1.mp4",
    "/videos/2.mp4",
    "/videos/4.mp4",
    "/videos/5.mp4",
    "/videos/6.mp4",
  ];

  return (
    <>
      <Carousel fade className="slider">
        {/* Boucle sur le tableau des vidéos */}
        {videos.map((videoSrc, index) => (
          <Carousel.Item key={index}>
            <video autoPlay loop muted className="background-video">
              <source src={videoSrc} type="video/mp4" />
            </video>
        
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};

export default HomePage;
