import StillVideos from '../../../components/stills/stillVideos'
import React from 'react'

export const metadata = {
  title: "portfolio Videos",
  description: "Browse through a curated selection of videos featuring stills and behind-the-scenes footage from Saleh Ismael's projects. Experience the dynamic aspects of his work in audiovisuals.",
};
const StillsVideos = () => {
  return (
    <div>
     <StillVideos/>
     
    </div>
  )
}

export default StillsVideos
