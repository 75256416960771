import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import UserLayout from "../layouts/user-layout";
import Error404Page from "../pages/errors/error-404";
import Error401Page from "../pages/errors/error-401";

import Home from "../pages/homepage/page";
import AboutPage from "../pages/about/page";
import Contact from "../pages/contact/page";
import Stills from "../pages/portfolio/page";
import StillsImages from "../pages/portfolio/images/page";
import StillsVideos from "../pages/portfolio/videos/page";
import HomePage from "../components/homepage/slider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <UserLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },

      {
        path: "salehismael",
        element: <HomePage />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "portfolio",
        element: <Stills />,
      },
      {
        path: "portfolio/images",
        element: <StillsImages />,
      },
      {
        path: "portfolio/videos",
        element: <StillsVideos />,
      },

      {
        path: "unauthorized",
        element: <Error401Page />,
      },
      {
        path: "*",
        element: <Error404Page />,
      },
    ],
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
