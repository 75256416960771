import React from 'react';

const Footer = () => {
  return (
    <footer className='bg-primary text-white text-center p-4'>
      <p>&copy; 2024 - Tous droits réservés.</p>
      <p>
        Conçu par <a href="https://mohamed-ali-youssouf.com/" className="text-white" target="_blank" rel="noopener noreferrer">Mohamed Ali Youssouf</a>
      </p>
   
    </footer>
  );
};

export default Footer;
