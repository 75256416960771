"use client"

// components/StillsVideos.js
import React from "react";

const data = [
  {
    title: "D-money ",
    url: "https://youtu.be/-SZ2YEAVDv0?si=hB7qVCACbmTcd7dp",
  },
  {
    title: "RedBull (BKE) ",
    url: "https://youtu.be/UkYnxyf9LHk?si=PsxPA8fFreyWPOfE",
  },
  {
    title: "En Deneme Club ",
    url: "https://youtu.be/ruT5bEiBGXA?si=mICCdAo9wCAJ94zY",
  },
  {
    title: "Yener Çevik (Color B) ",
    url: "https://youtu.be/ZDMn0RqgOzc?si=7wR8BObQMKpnle4a",
  },
  {
    title: "Arap Kadri ",
    url: "https://youtu.be/vPq5_k4glws?si=h543yeOQgiLVOule",
  },
  {
    title: "Yaz Şarkısı (Color B) ",
    url: "https://youtu.be/PcgkwOAQab4?si=sYnSm3s067leKGj3",
  },
  {
    title: "Şebeke (Color B) ",
    url: "https://youtu.be/gUYUK7_aqMg?si=Lc1EgTO7VQl6ZIiC",
  },
  {
    title: "UNFPA (INCOM) ",
    url: "https://youtu.be/nv9tn3bBbmQ?feature=shared",
  },
  {
    title: "CNSS Djibouti ",
    url: "https://youtu.be/xrnnDWRBRCA?si=SUuV03GUv6t_szlK",
  },
  {
    title: "Portrait ",
    url: "https://youtu.be/2q4v_PPQOZI?si=u48GKekFqjScnr8W",
  },
  {
    title: "Regus Djibouti ",
    url: "https://youtu.be/V6Z5Vp7RApM?feature=shared",
  },
  {
    title: "Agribusiness ",
    url: "https://youtu.be/vC0LDW1WAEk?feature=shared",
  },
  {
    title: "ANPH ",
    url: "https://youtu.be/TYWtz1eJgb8?si=_VIveWWbA4BUoA_4",
  },
  {
    title: "4G+ ",
    url: "https://youtu.be/fSSk4vKTNe4?si=WOpc6IQNm4MSI2LL",
  },
  
];

const StillsVideos = () => {
  const isYouTubeVideo = (url) => url.startsWith("http");
  return (
    <div className="stills-videos-container">
    
    <div className="videos-grid">
      {data.map((video, index) => (
        <div className="video-item" key={index}>
          {isYouTubeVideo(video.url) ? (
            <iframe
              src={video.url.replace("youtu.be", "www.youtube.com/embed")}
              title={video.title}
              frameBorder="0"
              allowFullScreen
              width="100%"
              height="200"
            ></iframe>
          ) : (
            <video controls width="100%" height="200" preload="metadata">
              <source src={video.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          <h3 className="text-white">{video.title}</h3>
        </div>
      ))}
    </div>

    <style jsx>{`
      .stills-videos-container {
        padding: 20px;
        max-width: 1200px;
        margin: 0 auto;
      }
      h2 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 2.5rem;
      }
      .videos-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
      }
      .video-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      iframe, video {
        border-radius: 10px;
      }
      h3 {
        margin-top: 10px;
        text-align: center;
        font-size: 1.2rem;
      }
    `}</style>
  </div>

  );
};

export default StillsVideos;
