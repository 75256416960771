
import React from "react";
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa"; // For email, phone, location
import { FaLinkedin, FaInstagram } from "react-icons/fa"; // For social media
const ContactCard = () => {
  return (
    <div className="contact-section">
      <h2>Contact</h2>
      <div className="contact-details">
        <div className="card">
          <FaEnvelope size={24} color="#fff" />
          <p className="mt-2">
            <strong>Email:</strong>{" "}
            <a href="mailto:info@salehismael.com">info@salehismael.com</a>
          </p>
        </div>
        <div className="card">
          <FaPhoneAlt size={24} color="#fff" />
          <p className="mt-2">
            <strong>Phone:</strong> <a href="tel:+25377000000">+253 77 21 50 60 
            </a>
          </p>
        </div>
        <div className="card">
          <FaMapMarkerAlt size={24} color="#fff" />
          <p className="mt-2">
  <strong>Location:</strong> <span>&nbsp;Djibouti, Ville</span>
</p>

        </div>

        <div className="social-links">
          <h3>Suivez moi:</h3>
          <ul>
            <li className="card">
              <a
                href="https://www.linkedin.com/in/salah-ismael-4b473811a/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin size={24} color="#0070f3" /> LinkedIn
              </a>
            </li>
            <li className="card">
              <a
                href="https://www.instagram.com/salahsucrey/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size={24} color="#E1306C" /> Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>

      <style jsx>{`
        .contact-section {
          height: 100vh;
          background-color: #000;
          color: #f0f0f0; /* Light gray for better contrast */
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 20px;
        }

        h2 {
          font-size: 2.5rem;
          margin-bottom: 40px;
          color: #fff; /* White title */
        }

        .contact-details {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;
          max-width: 600px;
        }

        .card {
          background: #1a1a1a;
          padding: 20px;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .card:hover {
          transform: translateY(-5px);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.7);
        }

        .card p {
          margin: 0;
          font-size: 1.2rem;
          display: flex;
          align-items: center;
          color: #f0f0f0; /* Light gray text */
        }

        .card strong {
          margin-left: 10px;
          color: #f0f0f0; /* Consistent color */
        }

        .card a {
          color: #f0f0f0;
          text-decoration: none;
          margin-left: 10px;
        }

        .social-links {
          margin-top: 40px;
        }

        .social-links h3 {
          font-size: 1.5rem;
          margin-bottom: 20px;
          color: #fff; /* White subtitle */
        }

        .social-links ul {
          list-style-type: none;
          padding: 0;
          display: flex;
          justify-content: center;
          gap: 20px;
        }

        .social-links a {
          color: #0070f3; /* Custom color for each social media link */
          text-decoration: none;
          display: flex;
          align-items: center;
        }

        .social-links a:hover {
          text-decoration: underline;
        }
      `}</style>
    </div>
  )
}

export default ContactCard
