import React from 'react'
import StillCard from './stillCard'
import { Col, Container, Row } from 'react-bootstrap'
const Stills = () => {
  const stills = [
    { id: 1, src: "/images/2.jpg"},
    { id: 2, src: "/images/3.jpg" },
    { id: 3, src: "/images/4.jpg"},
    { id: 4, src: "/images/5.jpg"},
    { id: 5, src: "/images/6.jpg" },
    { id: 6, src: "/images/7.jpg"},
    { id: 7, src: "/images/8.jpg"},
  ];
  return (
    <Container>
      <Row className="row-cols-1 row-cols-sm-2 g-3 mt-2">
        {stills.map((still) => (
              <Col key={still.id}>
                <StillCard {...still} />
              </Col>
            ))
          }
      
      </Row>
    </Container>
  )
}

export default Stills
