import React from "react";
import About from "../../components/about/about";

export const metadata = {
  title: " À propos",
  description: "Découvrez le parcours de Saleh Ismael dans le cinéma et l'audiovisuel.",
};

const AboutPage = () => {
  return (
    <>
    <About/>
    </>
  );
};

export default AboutPage;
