import Stills from '../../../components/stills/stills'
import React from 'react'

export const metadata = {
  title: "Portfolio Images",
  description: "Explore a collection of still images showcasing various moments and works from Saleh Ismael's career. Dive into the visual storytelling and artistry behind the scenes.",
};
const StillsImages = () => {
  return (
    <div>
      <Stills/>
    </div>
  )
}

export default StillsImages
