import { Image } from "react-bootstrap";
import "./page.scss";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <div className="home position-relative">
      <video autoPlay loop muted className="background-video">
        <source src="/videos/6.mp4" type="video/mp4" />
        Professionnel de l’audiovisuel à Djibouti, avec une expertise en
        production et étalonnage, enrichie par une expérience dans l’industrie
        du cinéma turc. Passionné par la création d’images puissantes et engagé
        dans la formation des talents, je travaille en étroite collaboration
        avec des experts basés en France, en Turquie et à l’international.
        Découvrez mon univers et rejoignez-moi pour des projets sur-mesure.
      </video>
      <Helmet>
        <meta
          name="description"
          content="Professionnel de l’audiovisuel à Djibouti, avec une expertise en production et étalonnage, enrichie par une expérience dans l’industrie du cinéma turc. Passionné par la création d’images puissantes et engagé dans la formation des talents, je travaille en étroite collaboration avec des experts basés en France, en Turquie et à l’international. Découvrez mon univers et rejoignez-moi pour des projets sur-mesure."
        />
      </Helmet>

      <div className="overlay-content">
        <Image
          src="/images/profil.jpg"
          alt="Logo Saleh Sucrey"
          width={150}
          height={150}
          className="profil"
          loading="lazy"
        />

        <h1 className="title">Saleh Ismael</h1>
        <p className="text-white">DOP & COLORIST</p>

        <Link to="/salehismael">
          {" "}
          <button className="btn custom-btn">Voir mes œuvres</button>
        </Link>
        <div
          style={{ display: "flex", gap: "20px" }}
          className="justify-content-center mt-3"
        >
          {/* Instagram */}
          <a
            href="https://www.instagram.com/salahsucrey?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram size={30} className="text-white" />
          </a>
          {/* LinkedIn */}
          <a
            href="https://www.linkedin.com/in/salah-ismael-4b473811a/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FaLinkedinIn size={30} className="text-white" />
          </a>
        </div>
      </div>
    </div>
  );
}
