
import ContactCard from "../../components/contact/contactCard";


export const metadata = {
  title: "Contactez Saleh Ismael",
  description:
    "Prenez contact avec Saleh Ismael pour des demandes, des collaborations ou des opportunités de freelance. Trouvez les coordonnées et connectez-vous via les réseaux sociaux.",
};

const Contact = () => {
  return (
    <ContactCard/>
  );
};

export default Contact;
