"use client";
import React, { useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  Offcanvas,
  NavDropdown,
} from "react-bootstrap";
import "./index.scss";
import {
  FiHome,
  FiAward,
  FiCalendar,
  FiHeadphones,
  FiImage,
  FiVideo,
} from "react-icons/fi";
import { Link } from "react-router-dom";

const Header = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const openOffcanvas = () => setShowOffcanvas(true);
  const closeOffcanvas = () => setShowOffcanvas(false);

  return (
    <Navbar expand="lg" sticky="top" className="bg-primary">
      <Container className="d-flex justify-content-between align-items-center">
        {/* Brand */}
        <Navbar.Brand as={Link} to="/salehismael" className="me-auto">
          <h1 className="logo text-white">Saleh Ismael</h1>
        </Navbar.Brand>

        {/* Navbar Toggle (visible on small screens) */}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={openOffcanvas}
          className="ms-auto custom-toggler"
          aria-label="Toggle navigation"
        />

        {/* Offcanvas Menu (for small screens) */}
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="start"
          show={showOffcanvas}
          onHide={closeOffcanvas}
          className="offcanvas-width-quarter d-lg-none bg-primary fade"
        >
          <Offcanvas.Header closeButton className="bg-white">
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              <h1 className="logo pt-2" onClick={closeOffcanvas}>
                <Link to="/salehismael" style={{ textDecoration: "none" }}>
                  Saleh Ismael
                </Link>
              </h1>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <hr />
          <Offcanvas.Body>
            <Nav className="justify-content-center flex-grow-1 pe-3 gap-2">
              <Nav.Link
                className="text-white"
                to="/salehismael"
                as={Link}
                onClick={closeOffcanvas}
              >
                <FiAward /> Accueil
              </Nav.Link>
              <Nav.Link
                className="text-white"
                to="/about"
                as={Link}
                onClick={closeOffcanvas}
              >
                <FiAward /> À propos
              </Nav.Link>
              <NavDropdown
                title={
                  <>
                    <FiCalendar /> Portfolio
                  </>
                }
                id="offcanvasNavbarDropdown"
                className="text-white dropdown-hover"
              >
                <NavDropdown.Item
                  to="/portfolio/images"
                  as={Link}
                  onClick={closeOffcanvas}
                >
                  <FiImage /> Images
                </NavDropdown.Item>
                <NavDropdown.Item
                  to="/portfolio/videos"
                  as={Link}
                  onClick={closeOffcanvas}
                >
                  <FiVideo /> Videos
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                className="text-white"
                to="/contact"
                as={Link}
                onClick={closeOffcanvas}
              >
                <FiHeadphones /> Contact
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>

        {/* Navigation Links for larger screens */}
        <Nav className="d-none d-lg-flex ms-auto gap-3">
          <Nav.Link as={Link} to="/salehismael">
            <FiHome /> Accueil
          </Nav.Link>
          <Nav.Link as={Link} to="/about">
            <FiAward /> À propos
          </Nav.Link>
          <NavDropdown
            title={
              <>
                <FiCalendar /> Portfolio
              </>
            }
            id="navbarDropdown"
            className="text-white dropdown-hover"
          >
            <NavDropdown.Item as={Link} to="/portfolio/images">
              <FiImage /> Images
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/portfolio/videos">
              <FiVideo /> Videos
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/contact">
            <FiHeadphones /> Contact
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
